* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

//The header/footer layout uses flexbox properties that came from
//https://dev.to/domysee/keeping-the-footer-at-the-bottom-with-css-flexbox-5h5f?signin=true

html,
body {
  width: 100%;
  height: 100%;
}

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.article {
  display: flex;
  flex-grow: 1;}

.header,
.article,
.footer {
  flex-shrink: 0;
}

.stickyfooter {
  z-index: 1100; //same as app bar
  position: sticky;
  bottom: 0;
}

fieldset {
  border: 0px;
}