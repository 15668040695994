.header {
    min-height:30px;
    top:0px;
    left:0px;
    right:0px;
    padding:4px;
    .logo{
        background-repeat:  no-repeat;
        display:flex;
        height:auto;

    }   
}

